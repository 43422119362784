/* style.css */

.real-time-grids-container {
  display: flex;
  align-items: stretch;
}

.real-time-grid {
  display: flex;
  flex-direction: column;
}

.real-time-inner-col {
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: 10px;
    min-width: 0;
}

.real-time-inner-col.vertical-toolbar {
    display: flex;
    flex: none;
    width: 200px;
    /* width: 100%; */
    /* max-width: 150px; */
    align-items: center;
    justify-content: center;
}

.real-time-container {
  /* align-items: flex-start; */
  display: flex;
  flex-direction: column;
  /* position: relative; */
  /*height: 100%;  
  width: 100%*/;
  flex: 1 5 auto;
   
}

.real-time-divider {
  height: 40px;
}