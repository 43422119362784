body {
	margin: 0;
}

.flex-row {
	display: flex;
	flex-grow: 1;
}

.flex-column {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}
