.root {
    display: flex;
}

.fetchButton {
    color: white;
}

.textLabels {
    color: #272360;
    vertical-align: middle;
}

.inputBox {
    color: #FFFFFFDE;
    border: #272360;
}

.appBar {
    /* Note: 'z-index' and 'transition' use theme variables and need specific values */
    /* z-index: [theme.zIndex.drawer + 1]; */
    /* transition: [theme.transitions.create(['width', 'margin'], { easing: 'sharp', duration: 'leavingScreen' })]; */
}

.drawerToggleContainerOpen {
    position: absolute;
    height: 100vh;
    width: calc(var(--drawer-width) + 15px);
    /* Replace '--drawer-width' with actual value */
    display: flex;
    justify-content: flex-end;
    /* transition: [theme.transitions.create('width', { easing: 'sharp', duration: 'enteringScreen' })]; */
}

.drawerToggleContainerOpen:hover {
    cursor: pointer;
}

.drawerToggleContainerClosed {
    position: absolute;
    height: 100vh;
    width: calc(56px + 30px);
    /* Replace '56px' if 'theme.spacing(7)' differs */
    display: flex;
    justify-content: flex-end;
    /* transition: [theme.transitions.create('width', { easing: 'sharp', duration: 'leavingScreen' })]; */
}

.drawerToggleContainerClosed:hover {
    cursor: pointer;
}

.drawerToggle {
    align-self: center;
    border: 2px solid black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    position: fixed;
}

.menuButton {
    margin-right: 36px;
}

.hide {
    display: none;
}

.drawer {
    width: var(--drawer-width);
    /* Replace '--drawer-width' with actual value */
    flex-shrink: 0;
    white-space: nowrap;
    z-index: 1;
}

.drawerOpen {
    background: #ECEFF1;
    width: var(--drawer-width);
    /* Replace '--drawer-width' with actual value */
    /* transition: [theme.transitions.create('width', { easing: 'sharp', duration: 'enteringScreen' })]; */
}

.drawerClose {
    background: #ECEFF1;
    overflow-x: hidden;
    width: calc(56px + 1px);
    /* Replace '56px' if 'theme.spacing(7)' differs */
    /* transition: [theme.transitions.create('width', { easing: 'sharp', duration: 'leavingScreen' })]; */
}

@media (min-width: 600px) {
    .drawerClose {
        width: calc(72px + 1px);
        /* Replace '72px' if 'theme.spacing(9)' differs */
    }
}

.fastpathToolbar {
    display: flex;
    align-items: center;
    background: #ECEFF1;
    justify-content: flex-end;
    /* padding: [theme.spacing(0, 1)]; */
    /* Include styles from 'theme.mixins.toolbar' if needed */
}

.content {
    flex-grow: 1;
    /* padding: [theme.spacing(3)]; */
}

.inputMarginDense {
    padding-top: 2px;
    padding-bottom: 2px;
}

.checkboxRoot {
    color: #272360;
    padding: 0px;
}