.warning-text-field .MuiOutlinedInput-root {
    color: #ed6c02 !important;
}

.warning-text-field .MuiOutlinedInput-notchedOutline {
    border-color: #ed6c02 !important;
}

.warning-text-field .MuiInputLabel-outlined {
    color: #ed6c02;
    font-weight: bold;
}

.warning-text-field .MuiSvgIcon-root {
    color: #ed6c02;
}

.warning-text-field .MuiFormHelperText-root {
    color: #ed6c02;
}