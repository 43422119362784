

  .user-example-wrapper {
    display: flex;
    /* min-height: 100%;  */
    /* height: 100%;  */
    /* clear: both; */
}

.user-inner-col {
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: 10px;
    min-width: 0;
}

.user-inner-col.vertical-toolbar {
    display: flex;
    flex: none;
    width: 200px;
    min-width: 200px;
    align-items: center;
    justify-content: center;
}

.user-container {
  display: flex;
  flex-direction: column;
  /* position: relative; */
  height: 100%;  
  width: 100%;
  
}

.user-grid {
  flex: 1 1 auto;
  /* height: '45%'; */
  /* clear: both; */
}

.user-divider {
  height: 40px;
}

