.row-fail {
  color: red;
}

.row-confirmed {
  font-weight: bold;
  color: green;
}

.row-study {
  animation: blinkgreen 1s infinite;
}

.row-invalid {
  font-weight: bold;
  color: red;
}

.row-waiting {
  color: orange;
}

.row-refused {
  font-weight: bold;
  color: red;
}

.row-pass {
  color: green;
}

.row-success {
  color: darkgreen;
}

.row-queued {
  font-weight: bold;
  color: orange;
}

.row-annulled {
  color: rgb(255, 123, 0);
}

.row-accepted {
  color: green;
}

.row-rejected {
  color: red;
}

.row-withdrawn {
  color: darkgrey;
}

.row-counteroffer {
  animation: blinkgreen 1s infinite;
}

.row-missing-price {
  background-color: palevioletred;
}

.row-alert {
  animation: blinkblue 1s infinite;
}

.row-staged {
  animation: blinkblue 1s infinite;
}

.row-triggered {
  color: darkgreen;
}

.row-monitoring {
  color: orange;
}

.row-admin-alert {
  color: lightsalmon;
}

.row-trans-hunter-alert {
  color: green;
}

.row-expired {
  color: red;
}

.hour-cells {
  justify-content: center;
  padding-left: 1px;
  padding-right: 0px;
}

.custom-tooltip {
  position: absolute;
  width: 150px;
  height: 200px;
  border: 1px solid cornflowerblue;
  overflow: hidden;
  pointer-events: none;
  transition: opacity 1s;
}

.custom-tooltip.ag-tooltip-hiding {
  opacity: 0;
}

.custom-tooltip p {
  margin: 5px;
  white-space: nowrap;
}

.custom-tooltip p:first-of-type {
  font-weight: bold;
}

.template-table {
  padding: 10px;
}

.dense-grid {
  padding-left: 1px;
  padding-right: 0px;
}

.cell-fail {
  background-color: #c77a75;
  color: white;
}

@keyframes blinkgreen {
  0% {
    color: darkgreen;
  }

  20% {
    color: green;
  }

  40% {
    color: green;
  }

  60% {
    color: lightgreen;
  }

  80% {
    color: green;
  }

  90% {
    color: green;
  }

  100% {
    color: darkgreen;
  }
}

@keyframes blinkblue {
  0% {
    color: darkblue;
  }

  20% {
    color: mediumblue;
  }

  40% {
    color: blue;
  }

  60% {
    color: lightblue;
  }

  80% {
    color: blue;
  }

  90% {
    color: mediumblue;
  }

  100% {
    color: darkblue;
  }
}