:root {
  --ag-value-change-value-highlight-background-color: rgb(206, 206, 206) !important;
}

.default-class {
  background-color: lightgrey !important;
}

.my-shaded-effect {
  background-color: rgb(238, 235, 235) !important;
}

@keyframes event-flash {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  75% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.flashing-event {
  animation-name: event-flash;
  animation-duration: 2s;
}

.chart-container {
  background-color: #add8e6;
}

.hourly-increment-event {
  background-color: darkblue;
}

.daily-increment-event {
  background-color: darkred;
}

.weekly-increment-event {
  background-color: darkgreen;
}

.monthly-increment-event {
  background-color: darkgoldenrod;
}

.yearly-increment-event {
  background-color: rebeccapurple;
}

.gridViewWrapper {
  width: 100%;
  padding-left: 30px;
  padding-right: 10px;
}

.searchDetails {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  vertical-align: middle;
}

.searchDetails p {
  margin-right: 30px;
}

.dataGrid {
  border: 1px solid #87b7eb;
  width: 100%;
  border-collapse: collapse;
}

.dataGrid tr {
  border: 1px solid #87b7eb;
}

.subRowGrid {
  display: flex;
}

.subRowFixedTable {
  border-collapse: collapse;
}

.subRowFixedTable thead {
  background-color: #efefef;
}

.subRowFixedTable tr:last-child td {
  border: none;
}

.subRowFixedTable tr:not(:last-child) {
  background-color: #efefef;
}

.subRowFixedTable tr th {
  border: 1px solid #87b7eb;
  width: 180px;
  height: 25px;
}

.subRowFixedTable tr th:first-child {
  max-width: 40px;
  border-right: none;
}

.subRowFixedTable tr th:not(:first-child) {
  border-left: none;
}

.subRowFixedTable tr th:nth-child(2) {
  min-width: 40px;
}

.subRowFixedTable tr td {
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  border: 1px solid #87b7eb;
  width: 120px;
  height: 25px;
}

.subRowFixedTable tr td:first-child {
  max-width: 40px;
  border-right: none;
}

.subRowFixedTable tr td:not(:first-child) {
  border-left: none;
}

.scrollTableWrapper {
  overflow: auto;
  width: 100%;
}

.scrollTableWrapper::-webkit-scrollbar {
  background-color: #dddddd;
  height: 25px;
}

.scrollTableWrapper::-webkit-scrollbar-thumb {
  background-color: #aeaeae;
}

.subRowScrollTable {
  border-collapse: collapse;
}

.subRowScrollTable tr th {
  font-size: 10px;
  border: 1px solid #87b7eb;
  min-width: 50px;
  height: 25px;
}

.subRowScrollTable tr td {
  font-size: 10px;
  border: 1px solid #87b7eb;
  width: 50px;
  height: 25px;
  text-align: center;
}