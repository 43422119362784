.day-ahead-example-wrapper {
  display: flex;
}

.day-ahead-inner-col {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 10px;
  min-width: 0;
}

.day-ahead-inner-col.vertical-toolbar {
  display: flex;
  flex: none;
  width: 200px;
  align-items: center;
  justify-content: center;
}

.day-ahead-container {
  display: flex;
  flex-direction: column;
  height: 100%;  
  width: 100%;
  
}

.day-ahead-grid {
  flex: 1 1 auto;
}

.day-ahead-divider {
  height: 40px;
}