.update-card-container {
    height: 100%;
}

.update-card-title {
    font-size: 30px;
    margin-top: 10px;
}

.update-card-description {
    font-size: 18px;
}

.update-card-actions {
    padding-left: 6px; 
    padding-right: 6px;
    width: 100%; 
    height: 40px; 
}

.update-card-date {
    font-size: 14px;
    color: #9e9e9e;
    top: 0;
    left: 0;
    margin: 0;
}

.update-card-image {
  width: 96%; 
  position: relative;
  max-height: 450px;
  top: 8px;
  object-fit: contain;
}

.product-update-container {
    display: flex;
}