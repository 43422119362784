.selected-card {
    background-color: #c9c9c9;
}

.card {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.cardMedia {
    padding-top: 56.25%;
}

.cardContent {
    flex-grow: 1;
}

.cardHeader {
    background-color: rgb(233, 233, 233);
}