/* style.css */


  .real-time-originals-example-wrapper {
    display: flex;
    /* min-height: 100%;  */
    /* height: 100%;  */
    /* clear: both; */
}

.real-time-originals-inner-col {
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: 10px;
    min-width: 0;
}

.real-time-originals-inner-col.originals-vertical-toolbar {
    display: flex;
    flex: none;
    width: 200px;
    /* width: 100%; */
    /* max-width: 150px; */
    align-items: center;
    justify-content: center;
}

.real-time-originals-container {
  /* align-items: flex-start; */
  display: flex;
  padding: 2px;
  flex-direction: column;
  /* position: relative; */
  /* height: 110%;   */
  width: 100%;
  
}

.real-time-originals-grid {
  flex: 1 1 auto;
  /* padding: 5px; */
  /* height: '45%'; */
  /* clear: both; */
}

.real-time-originals-divider {
  height: 10px;
}

