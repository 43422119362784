:root {
  --ag-value-change-value-highlight-background-color: rgb(206, 206, 206) !important;
}

.large-checkbox {
  transform: scale(1.5);
}

@keyframes event-flash {
  0% { opacity: 1; }
  25% { opacity: 0; }
  50% { opacity: 1; }
  75% { opacity: 0; }
  100% { opacity: 1; }
}

.flashing-event {
  animation-name: event-flash;
  animation-duration: 2s;
}

.row-counteroffer {
  /* color: mediumblue !important;  */
  animation: blinkgreen 1s infinite;
}

@keyframes blinkgreen{
  0%{     color: darkgreen;  }
  20%{     color: green;  }
  40%{     color: green;  }
  60%{    color: lightgreen; }
  80%{    color: green; }
  90%{    color: green;  }
  100%{   color: darkgreen;    }
}

.row-alert {
  /* color: mediumblue !important;  */
  animation: blink 1s infinite;
}

.row-staged {
  /* color: mediumblue !important;  */
  animation: blink 1s infinite;
}

@keyframes blink{
  0%{     color: darkblue;  }
  20%{     color: mediumblue;  }
  40%{     color: blue;  }
  60%{    color: lightblue; }
  80%{    color: blue; }
  90%{    color: mediumblue;  }
  100%{   color: darkblue;    }
}

.row-triggered {
  color: darkgreen !important;  
}

.row-refused{
  color: red !important; 
}

.row-invalid{
  color: darkred !important; 
}

.row-confirmed {
  color: green !important; 
}

.row-queued {
  color: orange !important; 
}

.row-success {
  color: darkgreen !important; 
}

.row-withdrawn {
  color: darkgrey !important; 
}



.row-missing-price {
  background-color: palevioletred !important;
}

.chart-container {
  background-color: #add8e6;
}

.hourly-increment-event {
  background-color: darkblue;
}

.daily-increment-event {
  background-color: darkred;
}

.weekly-increment-event {
  background-color: darkgreen;
}

.monthly-increment-event {
  background-color: darkgoldenrod;
}

.yearly-increment-event {
  background-color: rebeccapurple;
}