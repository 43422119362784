:root {
	--powerstation-green: #4dad08;
	--powerstation-red: #FF6666;
	--powerstation-yellow: #CEDA28;
}

.ag-custom-total-row {
	background-color: #f5f5f5;
	font-weight: bold;
	font-size: 1.2em;
	color: #333;
}

.ag-custom-total-row-dark {
	background-color: #2d3436;
	font-weight: bold;
	font-size: 1.2em;
	color: white;
}

.ag-row-purchase {
	background-color: #e0f7e0 !important;
}

.ag-row-sale {
	background-color: #fce7ec !important;
}

.ag-row-purchase-dark {
	background-color: #253726 !important;
}

.ag-row-sale-dark {
	background-color: #301d1e !important;
}

.ag-row-schedule {
	background-color: #ffffe6 !important;
}

.ag-row-schedule-dark {
	background-color: #4d4d00 !important;
}

.ag-row-deal {
	background-color: #d9f2d9 !important;
}

.ag-row-deal-dark {
	background-color: #0d260d !important;
}

.ag-row-bookout {
	background-color: #ffe6e6 !important;
}

.ag-row-bookout-dark {
	background-color: #1a0000 !important;
}

.ag-row-draft-tsr {
	background-color: #fce7ec;
}

.ag-row-draft-tsr-dark {
	background-color: #44070c;
}

.ag-group-row-cell {
	background-color: rgb(234, 243, 255);
}

.ag-group-row-cell-dark {
	background-color: rgb(6, 0, 19);
}

.ag-curtailed-market-text {
	color: var(--powerstation-red);
}

.ag-cell-text {
	font-size: 1em;
}

.ag-cell-text-bold {
	font-size: 1em;
	font-weight: bold;
	text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
}


.ag-cell-no-schedule-id {
	background-color: #fce7ec !important;
}

.ag-cell-no-schedule-id-dark {
	background-color: #301d1e !important;
}

.ag-row-discrepancy {
	background-color: #fce7ec !important;
}

.ag-row-discrepancy-dark {
	background-color: #301d1e !important;
}

.ag-cell-discrepancy {
	background-color: #E8C5CD !important;
}

.ag-cell-discrepancy-dark {
	background-color: #754649 !important;
}

.corner-flag-green {
	position: relative;
}

.corner-flag-green::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 0;
	border-top: 17px solid var(--powerstation-green);
	border-right: 17px solid transparent;
}

.corner-flag-green {
	position: relative;
}

.corner-flag-yellow::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 0;
	border-top: 17px solid var(--powerstation-yellow);
	border-right: 17px solid transparent;
}

.corner-flag-red {
	position: relative;
}

.corner-flag-red::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 0;
	border-top: 17px solid var(--powerstation-red);
	border-right: 17px solid transparent;
}

.corner-flag-split {
	position: relative;
}

.corner-flag-split::before,
.corner-flag-split::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 0;
}

.corner-flag-split::before {
	/* Adjust the size as needed */
	border-top: 18px solid var(--powerstation-green);
	border-right: 18px solid transparent;
}

.corner-flag-split::after {
	border-top: 9px solid transparent;
	border-right: 9px solid transparent;
	border-bottom: 9px solid transparent;
	border-left: 9px solid var(--powerstation-red);
}
