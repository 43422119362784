.cert-example-wrapper {
  display: flex;
}

.cert-inner-col {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 10px;
  min-width: 0;
}

.cert-inner-col.vertical-toolbar {
  display: flex;
  flex: none;
  width: 250px;
  min-width: 250px;
  align-items: center;
  justify-content: center;
}

.cert-container {
  display: flex;
  flex-direction: column;
  height: 100%;  
  width: 100%;
}

.cert-grid {
  flex: 1 1 auto;
}

.cert-divider {
  height: 40px;
}